exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-crowdfund-js": () => import("./../../../src/pages/crowdfund.js" /* webpackChunkName: "component---src-pages-crowdfund-js" */),
  "component---src-pages-cryptogovernance-js": () => import("./../../../src/pages/cryptogovernance.js" /* webpackChunkName: "component---src-pages-cryptogovernance-js" */),
  "component---src-pages-digitalnations-js": () => import("./../../../src/pages/digitalnations.js" /* webpackChunkName: "component---src-pages-digitalnations-js" */),
  "component---src-pages-ideagenerators-js": () => import("./../../../src/pages/ideagenerators.js" /* webpackChunkName: "component---src-pages-ideagenerators-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-internetnative-js": () => import("./../../../src/pages/internetnative.js" /* webpackChunkName: "component---src-pages-internetnative-js" */),
  "component---src-pages-makingcryptomultiplayer-js": () => import("./../../../src/pages/makingcryptomultiplayer.js" /* webpackChunkName: "component---src-pages-makingcryptomultiplayer-js" */),
  "component---src-pages-marketplaceforwriting-js": () => import("./../../../src/pages/marketplaceforwriting.js" /* webpackChunkName: "component---src-pages-marketplaceforwriting-js" */),
  "component---src-pages-newearlyteam-js": () => import("./../../../src/pages/newearlyteam.js" /* webpackChunkName: "component---src-pages-newearlyteam-js" */),
  "component---src-pages-newinternetlogic-js": () => import("./../../../src/pages/newinternetlogic.js" /* webpackChunkName: "component---src-pages-newinternetlogic-js" */),
  "component---src-pages-newmvp-js": () => import("./../../../src/pages/newmvp.js" /* webpackChunkName: "component---src-pages-newmvp-js" */),
  "component---src-pages-nextgendaos-js": () => import("./../../../src/pages/nextgendaos.js" /* webpackChunkName: "component---src-pages-nextgendaos-js" */),
  "component---src-pages-nftsart-js": () => import("./../../../src/pages/nftsart.js" /* webpackChunkName: "component---src-pages-nftsart-js" */),
  "component---src-pages-onchainsvg-js": () => import("./../../../src/pages/onchainsvg.js" /* webpackChunkName: "component---src-pages-onchainsvg-js" */),
  "component---src-pages-refactorcamp-js": () => import("./../../../src/pages/refactorcamp.js" /* webpackChunkName: "component---src-pages-refactorcamp-js" */),
  "component---src-pages-remotework-js": () => import("./../../../src/pages/remotework.js" /* webpackChunkName: "component---src-pages-remotework-js" */),
  "component---src-pages-scissorlabels-js": () => import("./../../../src/pages/scissorlabels.js" /* webpackChunkName: "component---src-pages-scissorlabels-js" */),
  "component---src-pages-smallaudience-js": () => import("./../../../src/pages/smallaudience.js" /* webpackChunkName: "component---src-pages-smallaudience-js" */),
  "component---src-pages-spatialinterfaces-js": () => import("./../../../src/pages/spatialinterfaces.js" /* webpackChunkName: "component---src-pages-spatialinterfaces-js" */),
  "component---src-pages-spatialsoftware-js": () => import("./../../../src/pages/spatialsoftware.js" /* webpackChunkName: "component---src-pages-spatialsoftware-js" */),
  "component---src-pages-unbundling-js": () => import("./../../../src/pages/unbundling.js" /* webpackChunkName: "component---src-pages-unbundling-js" */)
}

